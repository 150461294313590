/**
 * @file It contains scripts for reply message card.
 */
import ThemisDecision from "@/components/shared/decision"
import ThemisFileComponent from "@/components/shared/file"
import ThemisPopOver from "@/components/shared/pop-over"

export default {
  name      : "ReplyMessageCard",
  components: { ThemisDecision, ThemisFileComponent, ThemisPopOver },
  data      : () => ({
    textMessage                       : undefined,
    isSendMessageDecisionDialogVisible: false,
    files                             : new Array,
    isAttachmentDialogVisible         : false,
    isAttachmentsIssuesDialogVisible  : false,
    notExistingFiles                  : new Array
  }),
  props: {
    value                     : Object,
    pChannel                  : Object,
    pIsUploadingAttachments   : Boolean,
    pReporterLanguage         : Object,
    pIsSendingReport          : Boolean,
    pIsReportSent             : Boolean,
    pDisable                  : Boolean,
    pTextMessage              : String,
    pIsNotExistingFilesRemoved: Boolean
  },
  methods: {
    handleDeleteFile(removedFile) {
      this.files = this.files?.filter(file => file.name !== removedFile.name
        && file.type !== removedFile.type)
      this.$emit("removeFile", removedFile.id)
    },
    emitSaveAttachmentEvent() {
      if (!this.isFileUploadLimitReached) {
        const filesToUpload = []
        for (const file of this.files) {
          if (file.size > this.$CONSTANTS.FILE_SIZE_LIMIT) {
            this.$emit("fileSizeError", file)
          } else {
            filesToUpload.push(file)
          }
        }
        this.files = filesToUpload
        if (this.files.length > 0) {
          this.$emit("saveFiles", this.files)
        }
      } else {
        this.files = new Array
      }
    },
    handleSendMessage() {
      this.$emit("sendMessage", this.textMessage)
      this.files = new Array
    },
    handleSendButtonClick() {
      for(const savedFile of this.savedFiles) {
        if(!savedFile.fileExists) {
          this.notExistingFiles.push(savedFile)
        }
      }
      if(this.notExistingFiles.length) {
        this.isAttachmentsIssuesDialogVisible = true
      } else {
        this.isSendMessageDecisionDialogVisible = true
      }
    },
    sendAnyway() {
      this.$DECISIONS.FILE_NOT_AVAILABLE.pActions[1].buttonProps.loading = true
      this.$emit("removeFiles", this.notExistingFiles)
    },
    goBack() {
      for (const messageItem of this.notExistingFiles) {
        this.$emit("removeFile", messageItem.id)
      }
      this.isAttachmentsIssuesDialogVisible = false
      this.notExistingFiles                 = new Array
    }
  },
  computed: {
    savedFiles() {
      let savedFiles = []
      if (this.value?.messageItems?.length) {
        savedFiles = this.value.messageItems.filter(messageItem =>
          messageItem.type !== this.$CONSTANTS.MESSAGE_ITEM_TYPE.TEXT)
      }
      return savedFiles
    },
    isMobileView() {
      return this.$vuetify.breakpoint.smAndDown
    },
    allowableFileFormats() {
      const allowableFileFormats = []
      for(const messageItemType of Object.values(this.$CONSTANTS.MESSAGE_ITEM_TYPE)) {
        if (messageItemType !== this.$CONSTANTS.MESSAGE_ITEM_TYPE.TEXT) {
          allowableFileFormats.push(messageItemType)
        }
      }
      return allowableFileFormats
    },
    savedAndUnsavedFiles() {
      const result = this.savedFiles.map(savedFile => ({
        id        : savedFile.id,
        name      : savedFile.value.name,
        type      : savedFile.type,
        size      : savedFile.value.size,
        fileExists: savedFile.fileExists
      }))
      if (this.files?.length > 0 && result) {
        for (const file of this.files) {
          if (!result.find(savedFile => {
            return savedFile.name === file.name && savedFile.type === file.type && savedFile.size === file.size
          })) {
            result.push({
              name: file.name,
              type: file.type,
              size: file.size
            })
          }
        }
      }
      return result
    },
    language() {
      return this.pReporterLanguage?.name
    },
    isFileUploadLimitReached() {
      if (this.savedAndUnsavedFiles.length > this.$CONSTANTS.ALLOWABLE_FILE_LIMIT) {
        return true
      }
    }
  },
  watch: {
    pTextMessage: {
      immediate: true,
      handler  : function(newValue) {
        this.textMessage = newValue
      }
    },
    textMessage: {
      handler: function(newValue) {
        if (newValue !== this.pTextMessage) {
          this.$emit("update:pTextMessage", newValue)
        }
        if (newValue && (newValue.length < 50000 && newValue.length > 48000)) {
          this.$emit("textMessageLimitReached")
        }
      }
    },
    pIsSendingReport: {
      handler: function(newValue) {
        this.$DECISIONS.SEND_MESSAGE.pActions[1].buttonProps.loading       = newValue
        this.$DECISIONS.FILE_NOT_AVAILABLE.pActions[1].buttonProps.loading = newValue
      }
    },
    pIsReportSent: {
      handler: function(newValue) {
        if (newValue) {
          this.isSendMessageDecisionDialogVisible = false
          this.isAttachmentsIssuesDialogVisible   = false
          this.textMessage                        = ""
          this.$refs.vo_reply.reset()
        }
      }
    },
    pIsNotExistingFilesRemoved: {
      handler: function(newValue) {
        if (newValue) {
          this.handleSendMessage()
        }
      }
    }
  }
}